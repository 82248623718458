import React from "react"

import Layout from "../layouts/layout"

import Breadcrumb from "../components/breadcrumb"

function ContactPage() {
    return(
        <Layout title="Contact">
            <div className="mt-3">
                <Breadcrumb title="Contact" />
            </div>
            <h1 className="title">Contact Form</h1>
            <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLScMB_oDjA67a_NFmTnCeZdivXu0iIxZ6ff6ise6CoHRZvg06Q/viewform?embedded=true" 
                className="mw-100"
                width="800" 
                height="1200" 
                frameBorder="0" 
                marginHeight="0" 
                marginWidth="0" 
                title='ContactForm'
            >
                読み込んでいます…
            </iframe>
        </Layout>
    )
}

export default ContactPage